
import { defineComponent, onMounted, ref } from "vue";
import Swiper, { Pagination, Navigation } from "swiper";
import Modal from "@/components/Ui/Modal.vue";
import useLastTransaction from "@/composables/useLastTransaction";
import { Video } from "@/types/home";

Swiper.use([Pagination, Navigation]);

export default defineComponent({
  name: "VideoCarousel",
  components: {
    Modal,
  },
  setup() {
    const selectedVideo = ref<Video>();
    const modal = ref<boolean>(false);
    const videoRef = ref<HTMLVideoElement>();
    const { videos } = useLastTransaction();

    const play = (index: number) => {
      selectedVideo.value = videos.value[index];
      modal.value = true;

      if (videoRef.value) {
        videoRef.value.src = videos.value[index].url;
        videoRef.value.play();
      }
    };

    const onHide = () => {
      if (videoRef.value) {
        videoRef.value.pause();
        videoRef.value.currentTime = 0;
        selectedVideo.value = undefined;
      }
    };

    onMounted(() => {
      new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
        },
      });
    });

    return {
      selectedVideo,
      videoRef,
      videos,
      play,
      onHide,
      modal,
    };
  },
});
