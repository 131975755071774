
import { defineComponent } from "vue";
import useLastTransaction from "@/composables/useLastTransaction";

export default defineComponent({
  name: "LatestTransactions",
  setup() {
    const { items } = useLastTransaction();

    return {
      items,
    };
  },
});
