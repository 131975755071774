
import { defineComponent } from "vue";
import TetherCalculator from "@/components/Shared/TetherCalculator/TetherCalculator.vue";
import VideoCarousel from "@/components/Page/Home/VideoCarousel.vue";
import LatestTransactions from "@/components/Page/Home/LatestTransactions.vue";

export default defineComponent({
  name: "HomeMobile",
  components: {
    TetherCalculator,
    VideoCarousel,
    LatestTransactions,
  },
});
